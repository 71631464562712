import React from 'react';
import ReactDOM from 'react-dom';

function App() {
  return (
    <div>
      <h1>Hello, React!</h1>
      <p>This is a basic React boilerplate.</p>
    </div>
  );
}

export default App;
